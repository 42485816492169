<template>
  <div>
    <v-btn @click="openAddTodoDialog" elevation="2" fab bottom fixed right color="green" dark>
      <v-icon>fas fa-plus</v-icon>
    </v-btn>
    <v-container>
      <v-layout v-for="(item, index) in todo_list" :key="index" row wrap justify-space-around mt-3>
        <v-flex xl6 lg6 xs12>
          <v-card class="my-2 rounded-lg elevation-10" >
            <v-card-title primary-title>
              {{item.title}}
              <v-spacer></v-spacer>
              <v-icon :color="item.color">fas fa-circle</v-icon>
            </v-card-title>
            <v-card-subtitle>
              {{item.description}}
            </v-card-subtitle>
            <v-card-actions>
              <v-btn color="error" icon @click="deleteTodo(index)">
                <v-icon>fas fa-trash</v-icon>
              </v-btn>
              <v-btn color="success" icon :to="'/edit/'+index">
                <v-icon>fas fa-edit</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <h3>{{item.date}}</h3>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      
     
    </v-container>
    <v-dialog v-model="add_todo_dialog" :overlay="false" max-width="500px" transition="scale-transition">
      <v-card>
        <v-card-title primary-title>
          Add New Todo
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="closeTodoDialog">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout row wrap justify-space-around>
              <v-flex xs12>
                <v-text-field v-model="todo.title" class="my-3" label="Todo Title" rounded outlined></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-around>
              <v-flex xs12>
                <v-textarea rounded v-model="todo.description" outlined label="Todo Description"></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-around>
              <v-flex xs12>
                <v-subheader>Todo Color</v-subheader>
                <v-btn @click="setTodoColor(item)" v-for="(item, index) in color_list" :key="index" :class="item" class="mx-1" x-large icon>
                  <v-icon color="white">fas fa-circle</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-around mt-4>
              <v-flex xs12>
                <v-text-field v-model="todo.date" class="my-5" label="Todo Date" rounded type="date" outlined></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-around>
              <v-flex xs12>
                <v-btn color="primary" @click="addTodo" x-large block rounded>Save Todo</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        todo : {
        },
        add_todo_dialog: false,
        color_list: [
          'green',
          'blue',
          'red',
          'pink',
          'yellow',
          'black'
        ]
      }
    },
    computed:{
      todo_list(){
        return this.$store.getters.todo_list
      }
    },
    methods: {
      setTodoColor(color){
        this.todo.color = color
      },
      openAddTodoDialog() {
        this.add_todo_dialog = true
      },
      closeTodoDialog() {
        this.add_todo_dialog = false
      },
      addTodo(){
        this.$store.commit('addTodo' , this.todo)
        this.todo = {}
        this.add_todo_dialog = false
      },
      deleteTodo(index){
        this.$store.commit('deleteTodo' , index)
      }
    },
  }
</script>