import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    todo_list : [
      
    ]
  },
  getters:{
    todo_list(state){
      return state.todo_list
    }
  },
  mutations: {
    addTodo(state , new_todo){
      state.todo_list.push(new_todo)
    },
    updateTodo(state , index , todo){
      state.todo_list[index] = todo
    },
    deleteTodo(state , index){
      state.todo_list.splice(index , 1)
    }
  },
})
